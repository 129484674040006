import { useNavigate, Link } from 'react-router-dom'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import logo2br2 from '../../assets/pictures/logo-2br-2.png'
import pagesHeadersArray from '../../datas/pagesHeadersArray.json'
import PagesHeaders from '../../components/PagesHeaders'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from '../../components/Footer'

// Toiture-img
import toitureA1 from '../../assets/pictures/pics/toiture-img/toiture-a1.jpg'
// Terrasse-img
import terrasseA1 from '../../assets/pictures/pics/terrasse-img/terrasse-a1.jpg'
// Revetement-sol-img
import revetementSolA1 from '../../assets/pictures/pics/revetement-sol-img/revetement-sol-a1.jpg'
// Platrerie-img
import platrerieA1 from '../../assets/pictures/pics/platrerie-img/platrerie-a1.jpg'
// Piscine-img
import piscineA1 from '../../assets/pictures/pics/piscine-img/piscine-a1.jpg'
// Partiulier-img
import particulierA1 from '../../assets/pictures/pics/particulier-img/particulier-a1.jpg'
// Maisons-img
import maisonsA1 from '../../assets/pictures/pics/maisons-img/maisons-a1.jpg'

import './m-galerie-card.css'
import './d-galerie-card.css'


const GalerieCard = () => {

    const galerieListArray = [
        {
            "id": "00002",
            "title": "Maçonnerie",
            "cover": `${maisonsA1}`,
            "description": ""
        },

        {
            "id": "00003",
            "title": "Divers particuliers",
            "cover": `${particulierA1}`,
            "description": "Divers particuliers"
        },

        {
            "id": "00004",
            "title": "Piscine",
            "cover": `${piscineA1}`,
            "description": ""
        },

        {
            "id": "00005",
            "title": "Plâtrerie",
            "cover": `${platrerieA1}`,
            "description": "Plâtrerie"
        },

        {
            "id": "00006",
            "title": "Revêtement sol",
            "cover": `${revetementSolA1}`,
            "description": ""
        },

        {
            "id": "00007",
            "title": "Terrasse",
            "cover": `${terrasseA1}`,
            "description": ""
        },

        {
            "id": "00008",
            "title": "Toiture",
            "cover": `${toitureA1}`,
            "description": ""
        },
    ]

    const navigate = useNavigate()
    const backToSite2 = () => navigate('/2br')

    localStorage.removeItem('token')
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const GaleriesHeader = pagesHeadersArray.find(el => el.title === "6")
    const GaleriesHeaderArray = []
    GaleriesHeaderArray.push(GaleriesHeader)

    return (
        <>
            <Helmet>
				<title>2brealisation.com - galerie de photos de chantier</title>
				<meta name="description"
					content="Page du site 2brealisation.com dédiée à la galerie de photos de chantier : maçonnerie, piscine, revêtements de sols et murs, électricité, plomberie, gros oeuvre, peinture, toiture, plâtrerie, terrasse" />
				<link rel="canonical" href="https://www.2brealisation.com/GalerieCard" />
			</Helmet>
            <section>
                <FontAwesomeIcon
                    onClick={backToSite2}
                    className="arrow-left arrow-position"
                    icon={faArrowLeft}
                />
                <Link to='/'>
                    <img   
                    className='navbar__logo-2br-D logo-margin-bottom' 
                    src={logo2br2} alt='2br-icon'   
                    />
                </Link>
                {GaleriesHeaderArray.map((item, index) => ( 
                    <PagesHeaders 
                        key={index}
                        titleCol1={item.titleCol1}
                        subTitle1={item.subTitle1}
                        subTitle2={item.subTitle2}
                        text1={
                            <div className='galerie-card'> 
                                {galerieListArray.map((items, id) => (
                                    <div className='galerie-card__chantier' key={id}>
                                        <Link to={`/Card/${items.id}`}>
                                            <img className='galerie-card__chantier__picture' src={items.cover} alt="chantier" />
                                            <div className='galerie-card__chantier__background'></div>
                                            <p className='galerie-card__chantier__title'>{items.title}</p>
                                        </Link>
                                    </div>
                                ))}
                            </div> 
                        }
                    />
                ))}
            </section>
            <Footer />
        </>
    )
}

export default GalerieCard