import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useEffect } from 'react'
//import failled from '../../public/assets/brands/logo-vowd-error.png'

// styles
import './m-error.css'
import './d-error.css'

const Error = () => {

    localStorage.removeItem('token')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
        <>
            <Helmet>
				<title>2brealisation.com - error</title>
				<meta name="description"
					content="Page error personnalisée du site 2brealisation.com" />
                <link rel="*" />
			</Helmet>
            <section className='error'>
                <h1 className='error__statut'>404</h1>
                {/*<img className='error__failled' src={failled} alt='logo-vowd-falled'/>*/}
                <h2 className='error__subheader'>Oups! Une erreur est survenue!</h2>
                <Link className='error__link' to='https://www.2brealisation.com'>
                    <p>
                        Retour au site
                    </p>
                </Link>
            </section>
        </>
	)
}
 
export default Error