import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const Passerelle2 = () => {
    const navigate = useNavigate()

    useEffect(()=> {
		navigate('/')
	}, [navigate] )

  return (
    <>
			<Helmet>
				<title>2brealisation.com - accueil2</title>
				<meta name="description"
					content="2br réalisation est une société basée à Toulouse spécialisée dans le secteur du btp et qui fédère tous les corps de métier du bâtiment dans la réalisation de chantiers, du travaux de rafraîchissement au travaux de rénovation ainsi que gros ouevre." />
				<link rel="canonical" href="https://www.2brealisation.com/copie-de-en-savoir-plus" />
			</Helmet>
    </>
  )
}

export default Passerelle2